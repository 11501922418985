<template>
  <div>
    <b-loading :is-full-page="true" :active.sync="isLoading"></b-loading>

    <Navbar />

    <section>
      <b-tabs position="is-centered" class="block">
        <b-tab-item label="Pending">
          <b-table
            :data="pendingCarServices"
            :paginated="true"
            per-page="10"
            current-page.sync="1"
            pagination-position="bottom"
            default-sort-direction="asc"
            sort-icon="arrow-up"
            sort-icon-size="is-small"
            aria-next-label="Next page"
            aria-previous-label="Previous page"
            aria-page-label="Page"
            aria-current-label="Current page"
            hoverable
            striped
            :selected.sync="selectedCarServiceRequest"
          >
            <template slot-scope="props">
              <b-table-column field="date" label="Service date" sortable>
                {{ new Date(props.row.date).toLocaleDateString() }}
              </b-table-column>

              <b-table-column
                field="licensePlate"
                label="License plate"
                sortable
              >
                {{ props.row.car.licensePlate }}
              </b-table-column>

              <b-table-column field="make" label="Make" sortable>
                {{ props.row.car.make }}
              </b-table-column>

              <b-table-column field="model" label="Model" sortable>
                {{ props.row.car.model }}
              </b-table-column>

              <b-table-column
                field="year"
                label="Year"
                sortable
                numeric
                width="40"
              >
                {{ props.row.car.year }}
              </b-table-column>

              <b-table-column
                field="mileage"
                label="Mileage"
                sortable
                numeric
                centered
              >
                {{ props.row.car.mileage }}
              </b-table-column>
            </template>

            <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <p>
                    <b-icon icon="emoticon-sad" size="is-large"> </b-icon>
                  </p>
                  <p>No pending car services found</p>
                </div>
              </section>
            </template>

            <template slot="bottom-left">
              <button
                @click="approveCarService()"
                v-if="selectedCarServiceRequest !== null"
                class="button is-success"
              >
                <b-icon icon="check"></b-icon>
                <span>Approve request </span>
              </button>

              <button
                @click="selectedCarServiceRequest = null"
                v-if="selectedCarServiceRequest !== null"
                class="button is-danger has-margin-left-10"
              >
                <b-icon icon="close"></b-icon>
                <span>Clear selected</span>
              </button>
            </template>
          </b-table>
        </b-tab-item>

        <b-tab-item label="Approved">
          <b-table
            :data="approvedCarServices"
            :paginated="true"
            per-page="10"
            current-page.sync="1"
            pagination-position="bottom"
            default-sort-direction="asc"
            sort-icon="arrow-up"
            sort-icon-size="is-small"
            aria-next-label="Next page"
            aria-previous-label="Previous page"
            aria-page-label="Page"
            aria-current-label="Current page"
            hoverable
            striped
            :selected.sync="selectedApprovedCarService"
          >
            <template slot-scope="props">
              <b-table-column field="date" label="Service date" sortable>
                {{ new Date(props.row.date).toLocaleDateString() }}
              </b-table-column>

              <b-table-column
                field="licensePlate"
                label="License plate"
                sortable
              >
                {{ props.row.car.licensePlate }}
              </b-table-column>

              <b-table-column field="make" label="Make" sortable>
                {{ props.row.car.make }}
              </b-table-column>

              <b-table-column field="model" label="Model" sortable>
                {{ props.row.car.model }}
              </b-table-column>

              <b-table-column
                field="year"
                label="Year"
                sortable
                numeric
                width="40"
              >
                {{ props.row.car.year }}
              </b-table-column>

              <b-table-column
                field="mileage"
                label="Mileage"
                sortable
                numeric
                centered
              >
                {{ props.row.car.mileage }}
              </b-table-column>
            </template>

            <template slot="bottom-left">
              <button
                @click="finishCarService()"
                v-if="selectedApprovedCarService !== null"
                class="button is-success"
              >
                <b-icon icon="check"></b-icon>
                <span>Finish</span>
              </button>

              <button
                @click="selectedApprovedCarService = null"
                v-if="selectedApprovedCarService !== null"
                class="button is-danger has-margin-left-10"
              >
                <b-icon icon="close"></b-icon>
                <span>Clear selected</span>
              </button>
            </template>

            <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <p>
                    <b-icon icon="emoticon-sad" size="is-large"> </b-icon>
                  </p>
                  <p>No approved car services found</p>
                </div>
              </section>
            </template>
          </b-table>
        </b-tab-item>
        <b-tab-item label="Finished">
          <b-table
            :data="finishedCarServices"
            :paginated="true"
            per-page="10"
            current-page.sync="1"
            pagination-position="bottom"
            default-sort-direction="asc"
            sort-icon="arrow-up"
            sort-icon-size="is-small"
            aria-next-label="Next page"
            aria-previous-label="Previous page"
            aria-page-label="Page"
            aria-current-label="Current page"
            hoverable
            striped
            selectable
          >
            <template slot-scope="props">
              <b-table-column field="date" label="Service date" sortable>
                {{ new Date(props.row.date).toLocaleDateString() }}
              </b-table-column>

              <b-table-column
                field="licensePlate"
                label="License plate"
                sortable
              >
                {{ props.row.car.licensePlate }}
              </b-table-column>

              <b-table-column field="make" label="Make" sortable>
                {{ props.row.car.make }}
              </b-table-column>

              <b-table-column field="model" label="Model" sortable>
                {{ props.row.car.model }}
              </b-table-column>

              <b-table-column
                field="year"
                label="Year"
                sortable
                numeric
                width="40"
              >
                {{ props.row.car.year }}
              </b-table-column>

              <b-table-column
                field="mileage"
                label="Mileage"
                sortablex
                numeric
                centered
              >
                {{ props.row.car.mileage }}
              </b-table-column>
            </template>

            <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <p>
                    <b-icon icon="emoticon-sad" size="is-large"> </b-icon>
                  </p>
                  <p>No finished car services yet</p>
                </div>
              </section>
            </template>
          </b-table>
        </b-tab-item>
      </b-tabs>
    </section>

    <Footer />
  </div>
</template>

<script>
import { httpClient } from '@/api/httpClient.js'
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'CarServicesList',
  components: {
    Navbar,
    Footer
  },
  data() {
    return {
      isLoading: false,
      isLoadingButton: false,
      carServices: [],
      selectedCarServiceRequest: null,
      selectedApprovedCarService: null
    }
  },
  computed: {
    canApproveCarService() {
      return this.selectedCarServiceRequest !== null
    },
    pendingCarServices() {
      return this.carServices.filter(
        service => !service.isDateApproved && !service.isFinished
      )
    },
    approvedCarServices() {
      return this.carServices.filter(
        service => service.isDateApproved && !service.isFinished
      )
    },
    finishedCarServices() {
      return this.carServices.filter(service => service.isFinished)
    }
  },
  created() {
    this.getCarServices()
  },
  methods: {
    getCarServices() {
      this.isLoading = true
      httpClient
        .get('/car-services')
        .then(res => {
          this.isLoading = false
          this.carServices = res.data['hydra:member']
        })
        .catch(err => {
          this.isLoading = false

          this.$buefy.snackbar.open({
            message: `Error: ${err.message}`,
            type: 'is-danger',
            position: 'is-top'
          })
        })
    },
    approveCarService() {
      this.isLoading = true

      httpClient
        .get(`/car-services/${this.selectedCarServiceRequest.id}/approve`)
        .then(() => {
          this.isLoading = false
          this.$buefy.snackbar.open({
            message: `Car service is approved`,
            type: 'is-success',
            position: 'is-top'
          })
          this.getCarServices()
          this.selectedCarServiceRequest = null
        })
        .catch(err => {
          this.isLoading = false

          this.$buefy.snackbar.open({
            message: `Error: ${err.message}`,
            type: 'is-danger',
            position: 'is-top'
          })
        })
    },
    finishCarService() {
      this.isLoading = true

      httpClient
        .get(`/car-services/${this.selectedApprovedCarService.id}/finish`)
        .then(() => {
          this.isLoading = false
          this.$buefy.snackbar.open({
            message: `Car service is finished`,
            type: 'is-success',
            position: 'is-top'
          })
          this.getCarServices()
          this.selectedApprovedCarService = null
        })
        .catch(err => {
          this.isLoading = false

          this.$buefy.snackbar.open({
            message: `Error: ${err.message}`,
            type: 'is-danger',
            position: 'is-top'
          })
        })
    }
  }
}
</script>

<style scoped></style>
